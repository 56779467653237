<template>
  <div class="navigation dp-f ai-c jc-sb pl-20 pr-30">
    <div class="dp-f">
      <!-- <div class="mr-40">份数：1/{{state.copies}}</div> -->
      <div class="mr-40">页码：{{state.currentPage}}</div>
      <div>页面：{{state.currentPage}}/{{state.totalPages}}</div>
    </div>
    <!-- <div class="dp-f ai-c">
      <img class="cu-p w-14 h-14" src="@/assets/img/general/shrink.png" @click="setPercentage(-10)">
      <div class="ml-10 mr-10">{{ state.percentage }}%</div>
      <img class="cu-p w-14 h-14" src="@/assets/img/general/enlarge.png" @click="setPercentage(10)">
    </div> -->
  </div>
</template>
<script setup>
import { ref, unref, computed, watch, reactive,defineEmits } from "vue";
import { useStore } from "vuex";
const store = useStore();
const emit = defineEmits(['Emit'])
let state = reactive({
  copies:1,//份数
  currentPage:1,//当前页
  totalPages:1,//总页数
  percentage:100,//百分比
});
store.commit('sign/setcurrentPageTem', 1)
const setPages=((val)=>{
  state.currentPage=val
  store.commit('sign/setcurrentPageTem', val)
})
const setTotalPages=((val)=>{
  state.totalPages=val
})
// 设置份数
const setCopies=((val)=>{
  state.copies=val
})
const setPercentage=((val)=>{
  if(val>0){
    if(state.percentage>=150)
      return
  }else{
    if(state.percentage<=50)
      return
  }
  state.percentage+=val
  emit('Emit')
})
defineExpose({
  state:state,
  setPages,setTotalPages,setCopies
});
</script>
<style lang="scss" scoped>
.navigation{
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px -4px 4px 0px rgba(125,125,125,0.06);
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>